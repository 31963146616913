import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { filter, from, Subject, Subscription } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import {
  AvatarComponent,
  BlankStateComponent,
  ButtonComponent,
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  RadioComponent,
  RadioGroupComponent,
  SearchBarComponent,
  ShowOperationDividerPipe,
  TrackByPipe,
} from '@dougs/ds';
import { AccountingFilter, AccountingSearch, Operation } from '@dougs/operations/dto';
import { ModalOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { SynchronizedAccountStateService } from '@dougs/synchronized-accounts/shared';
import { OperationComponent } from '../../components/operations/operation/operation.component';

@Component({
  selector: 'dougs-operation-list-modal',
  templateUrl: './operation-list-modal.component.html',
  styleUrls: ['./operation-list-modal.component.scss'],
  providers: [{ provide: OPERATION_STATE_TOKEN, useExisting: ModalOperationsStateService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    InfiniteScrollModule,
    SearchBarComponent,
    AvatarComponent,
    CommonModule,
    LoaderComponent,
    TrackByPipe,
    ShowOperationDividerPipe,
    OperationComponent,
    BlankStateComponent,
    ButtonComponent,
    ReactiveFormsModule,
    DougsDatePipe,
    PanelInfoComponent,
    RadioComponent,
    RadioGroupComponent,
  ],
})
export class OperationListModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public isLoading = true;
  public searchFormControl: UntypedFormControl = new UntypedFormControl();
  public filtersFormControl: UntypedFormControl = new UntypedFormControl();

  public hasDoneOperationActions = false;

  private readonly queueScroll: Subject<void> = new Subject<void>();
  private readonly queueScroll$ = this.queueScroll.asObservable();
  private scrollSubscription!: Subscription;
  private searchSubscription!: Subscription;
  private operationsSubscription!: Subscription;
  private offset = 0;
  private resetOperationStateOnCloseSubscription!: Subscription;

  @ViewChild('subtitle') subtitleElementRef?: ElementRef;
  @ViewChild('searchbar') searchbarElementRef?: ElementRef;

  subtitleContainerHeight = this.subtitleElementRef?.nativeElement.height;
  searchbarContainerHeight = 49;

  private searchbarResizeObserver!: ResizeObserver;

  public readonly filters$ = this.filtersFormControl.valueChanges.pipe(
    distinctUntilChanged(),
    tap(() => this.initSearching(this.searchFormControl.value)),
    tap(() =>
      this.metricsService.pushMixpanelEvent('Accounting Operation Researched', {
        'CTA Location': 'Modal Operation List',
        'Modal Name': this.data.mixpanelModalName ?? 'Non défini',
      }),
    ),
    concatMap(() => this.refreshOperations()),
    tap(() => this.endSearching()),
  );

  public readonly operations$ = this.modalOperationsStateService.operations$.pipe(
    map((operations) => (this.mode === 'validation' ? operations.sort(this.sortValidatedOperation) : operations)),
  );

  public readonly hasValidatedAllOperations$ = this.modalOperationsStateService.operations$.pipe(
    map(
      (operations) =>
        this.data.enableValidationCompletedMessage && operations.every((operation) => !!operation.validated),
    ),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      search: AccountingSearch;
      filters: AccountingFilter[];
      title: string;
      summaryValues: {
        value: number;
        text?: string;
        colorClass?: string;
        customSuffix?: string;
        withDecimals?: boolean;
      }[];
      subtitle: string;
      preSubtitle?: string;
      avatarImage?: string;
      additionalInfo?: string;
      additionalClass?: string;
      mixpanelCTALocation?: string;
      mixpanelModalName?: string;
      enableValidationCompletedMessage?: boolean;
      mode?: 'default' | 'validation';
    },
    public readonly modalOperationsStateService: ModalOperationsStateService,
    private readonly companyStateService: CompanyStateService,
    public readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly metricsService: MetricsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
  ) {}

  get operationSearch(): AccountingSearch {
    return this.data.search;
  }

  get operationFilters(): AccountingFilter[] {
    return this.data.filters;
  }

  get mode(): string {
    return this.data.mode ?? 'default';
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    if (this.operationFilters && this.operationFilters[0].search) {
      this.filtersFormControl.setValue(this.operationFilters[0].search);
    }

    await this.refreshOperations();

    if (this.data.search.useVatOperationsCustomRoute) {
      this.operationsSubscription = this.modalOperationsStateService.operations$
        .pipe(
          filter((operations) => operations?.length < 2),
          tap(
            () =>
              (this.data.additionalInfo =
                '<p>Il peut y avoir un écart dans votre montant calculé. Cela est lié aux arrondis de votre<br>précédente déclaration de TVA: l’administration fiscale n’accepte pas les centimes dans la déclaration de TVA.</p>'),
          ),
        )
        .subscribe();
    }
    this.isLoading = false;
    this.cdr.markForCheck();
    this.metricsService.pushMixpanelEvent('Operation Modal Viewed', {
      'CTA Location': this.data.mixpanelCTALocation ?? 'Non défini',
      'Modal Name': this.data.mixpanelModalName ?? 'Non défini',
    });
    this.searchSubscription = this.searchFormControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap((searchValue: string) => this.initSearching(searchValue)),
        tap(() =>
          this.metricsService.pushMixpanelEvent('Accounting Operation Researched', {
            'CTA Location': 'Modal Operation List',
            'Modal Name': this.data.mixpanelModalName ?? 'Non défini',
          }),
        ),
        concatMap(() => this.refreshOperations()),
        tap(() => this.endSearching()),
      )
      .subscribe();

    this.resetOperationStateOnCloseSubscription = this.modalRef.beforeClosed$
      .pipe(tap(() => this.modalOperationsStateService.resetStateOperations()))
      .subscribe();

    this.scrollSubscription = this.queueScroll$
      .pipe(
        debounceTime(10),
        tap(() => this.offset++),
        concatMap(() =>
          from(
            this.modalOperationsStateService.loadMoreOperations(
              this.companyStateService.activeCompany.id,
              {
                ...this.operationSearch,
                ...(this.operationFilters && this.filtersFormControl.value ? this.filtersFormControl.value : null),
              },
              this.offset,
            ),
          ),
        ),
      )
      .subscribe();

    this.searchbarResizeObserver = new ResizeObserver((entries) => {
      if (entries[0]) {
        this.searchbarContainerHeight = this.searchbarElementRef?.nativeElement.offsetHeight - 1;
        this.cdr.markForCheck();
      }
    });

    this.searchbarResizeObserver.observe(this.searchbarElementRef?.nativeElement);
  }

  ngAfterViewInit(): void {
    this.subtitleContainerHeight = this.subtitleElementRef?.nativeElement.offsetHeight;
  }

  onScroll() {
    this.queueScroll.next();
  }

  onClearSearch(): void {
    this.searchFormControl.reset('');
  }

  ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
    this.searchSubscription?.unsubscribe();
    this.operationsSubscription?.unsubscribe();
    this.resetOperationStateOnCloseSubscription?.unsubscribe();
    this.searchbarResizeObserver?.unobserve(this.searchbarElementRef?.nativeElement);
  }

  private initSearching(searchValue: string): void {
    this.isLoading = true;
    this.cdr.markForCheck();
    this.offset = 0;
    this.operationSearch.search = searchValue;
  }

  private endSearching(): void {
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  closeModal(): void {
    this.modalRef.close();
  }

  private async refreshOperations(): Promise<void> {
    const search: AccountingSearch =
      this.operationFilters && this.filtersFormControl.value
        ? { ...this.operationSearch, ...this.filtersFormControl.value }
        : { ...this.operationSearch };

    await this.modalOperationsStateService.refreshOperations(this.companyStateService.activeCompany.id, search);
  }

  private sortValidatedOperation(a: Operation, b: Operation): number {
    return (
      Number(a.validated) - Number(b.validated) ||
      new Date(b.date).getTime() - new Date(a.date).getTime() ||
      b.id - a.id
    );
  }
}
